// External libs
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { connect } from "react-redux"
import * as firebase from "firebase/app"
import { toast } from "react-toastify"

// Components
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent"

import "./NavbarUser.scss"

function UserDropdown() {
  return (
    <DropdownMenu className="customDropdown" right>
      <DropdownItem tag="a" href="/minha-conta">
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Minha conta</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="#"
        onClick={() => firebase.auth().signOut()}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Sair</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

function NavbarUser(props) {
  const db = firebase.firestore();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [qtUnreadNotifications, setQtUnreadNotifications] = useState(0);
  const [dropdownNotificationOpen, setDropdownNotificationOpen] = useState(false);

  useEffect(() => {
    if (!props.auth?.values?.id) return;

    const unsubscribe = db.collection(`notifications/${props.auth.values.id}/data`).where("active", "==", true).orderBy("sendDate", "desc").onSnapshot(response => {
      const items = [];

      response.forEach(doc => {
        items.push({
          id: doc.id,
          ...doc.data()
        });
      });

      return setNotifications(items);
    });

    return () => {
      unsubscribe();
    }
  }, [props.auth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.auth?.values?.id || !props.auth?.values?.id) return;

    const unsubscribe = db.collection(`notifications/read/${props.auth.values.id}`).onSnapshot(response => {
      const items = [];

      response.forEach(doc => {
        items.push(doc.id);
      });

      setReadNotifications(items);
    });

    return () => {
      unsubscribe();
    }
  }, [props.auth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!notifications.length && !readNotifications.length) return;

    setQtUnreadNotifications(notifications.length - readNotifications.length);
  }, [notifications, readNotifications]);

  async function handleAllRead(){
    try {
      const allNotifications = notifications.map(item => item.id);
      const unReadNotifications = allNotifications.filter(item => !readNotifications.includes(item));

      if (unReadNotifications.length === 0) return;
      
      let readAt = new Date();
      readAt = readAt.getTime();

      const batch = db.batch();
      await unReadNotifications.map(async itemId => {
        const notificationRef = await db.doc(`notifications/read/${props.auth.values.id}/${itemId}`);
        batch.set(notificationRef, { readAt })
      })

      return batch.commit();
    } catch(error){
      toast.error(`Ocorreu um erro ao tentar executar a ação (${error.code})`);
    }
  }

  function handleOpenNotification(itemId){
    setDropdownNotificationOpen(false);
    history.push(`/notificacao/${itemId}`);
  }

  return (
    <ul className="nav navbar-nav navbar-nav-user float-right d-flex align-items-center">
      <Dropdown
        tag="li"
        className="dropdown-notification nav-item"
        isOpen={dropdownNotificationOpen} toggle={() => setDropdownNotificationOpen(!dropdownNotificationOpen)}
      >
        <DropdownToggle tag="a" className="nav-link nav-link-label">
          <Icon.Bell size={21} />
          {qtUnreadNotifications > 0 && (
            <Badge pill color="warning" className="badge-up">
              {" "}
              {qtUnreadNotifications}{" "}
            </Badge>
          )}
        </DropdownToggle>
        <DropdownMenu tag="ul" right className="dropdown-menu-media">
          <li className="dropdown-menu-header p-25"></li>
          <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{
              wheelPropagation: false
            }}
          >
            {notifications.length > 0 && notifications.map(item => (
              <div key={item.id} className="d-flex justify-content-between" onClick={() => handleOpenNotification(item.id)}>
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.PlusSquare
                      className={`font-medium-5 ${readNotifications.includes(item.id) ? "text-muted" : "warning"}`}
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className={`media-heading ${readNotifications.includes(item.id) ? "text-muted" : "warning"}`} tag="h6">
                      {item.title}
                    </Media>
                    <p className="notification-text">
                      {item.description}
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                    >
                      {moment(item.sendDate).fromNow()}
                    </time>
                  </small>
                </Media>
              </div>
            ))}
          </PerfectScrollbar>
          <li className="dropdown-menu-footer">
            <DropdownItem tag="a" className="p-1 text-center" onClick={handleAllRead}>
              <span className="align-middle">Marcar tudo como lido</span>
            </DropdownItem>
          </li>
        </DropdownMenu>
      </Dropdown>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item position-relative">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link pl-0 pr-50">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              {props.userName}
            </span>
            <span className="user-status">{props.clinicName}</span>
          </div>
          <span data-tour="user">
            <Avatar color="primary" content={props.userName?.charAt(0)} />
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(NavbarUser)