import React, { useEffect, useState } from "react"
import * as firebase from "firebase"
import { connect } from "react-redux"
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"

import { ShoppingBag } from "react-feather"

function ThemeNavbar(props) {
  const db = firebase.firestore();
  const [urlDental, setUrlDental] = useState("");
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"];

  useEffect(() => {
    if (!props.auth?.values?.dentalId || urlDental) return;

    async function getUrlDental(){
      try {
        const response = await db.doc(`catalogs/${props.auth.values.dentalId}`).get();
  
        return setUrlDental(response.data()?.link || "");
      } catch (error){
        return console.log(error);
      }
    }
    
    getUrlDental();
  }, [props.auth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <div className="bookmark-wrapper d-flex align-items-center">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />

                {urlDental && (
                  <a
                    className='btn btn-success px-50 ml-50 ml-sm-1 ml-xl-0 px-sm-1'
                    color='success'
                    href={urlDental}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <ShoppingBag className="mr-50 d-none d-sm-inline-block" size={20} />

                    Minha Dental
                  </a>
                )}
              </div>

              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null}
              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={props.auth?.values?.name}
                clinicName={props.auth?.values?.clinic}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
      auth: state.auth
  }
}

export default connect(mapStateToProps, null)(ThemeNavbar)