import React from "react"
import {
  Home,
  Calendar,
  Settings,
  Users,
  Star,
  HelpCircle,
  Package,
  FileText,
  CreditCard
} from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "calendar",
    title: "Agenda",
    type: "item",
    icon: <Calendar size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/agenda"
  },
  {
    id: "customers",
    title: "Pacientes",
    type: "item",
    icon: <Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/pacientes"
  },
  {
    id: "stock",
    title: "Estoque",
    type: "item",
    icon: <Package size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/estoque"
  },
  {
    id: "settings",
    title: "Configurações",
    type: "collapse",
    icon: <Settings size={20} />,
    children: [
      {
        id: "plans",
        title: "Planos",
        type: "item",
        icon: <CreditCard size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/configuracoes/planos"
      },
      {
        id: "procedures",
        title: "Procedimentos",
        type: "item",
        icon: <Star size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/configuracoes/procedimentos"
      },
      {
        id: "anamnesis",
        title: "Anamnese",
        type: "item",
        icon: <FileText size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/configuracoes/anamnese"
      },
      {
        id: "team",
        title: "Equipe",
        type: "item",
        icon: <Users size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/configuracoes/equipe"
      }
    ]
  },
  {
    id: "faq",
    title: "FAQ",
    type: "item",
    icon: <HelpCircle size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/faq"
  }
]

export default navigationConfig
